var docName = '';
var base_url = "https://docendorse.com";
var dialogBox = null;
var isRunning = false;
var refreshOnDialogClose = false;
var default_name = "unsaved_document";
var default_ext = ".docx";
var main_container = grab('.main_container');
var secondary_container = grab('.profile-card');
var loading_cover = grab('.loading_cover');
var spinner = grab('.spinner');


function installAddIn(info){

  if (info.host === Office.HostType.Word || info.host === Office.HostType.PowerPoint || info.host ===  Office.HostType.Excel ){
    secondary_container.hideItem();
    main_container.showItem();

    if( info.host === Office.HostType.PowerPoint ){
      default_ext = ".pptx";
    } else if (info.host ===  Office.HostType.Excel){
      default_ext = ".xlsx"
    }
      
    var titles = Array.from(grabAll('.document_name_or_title')),
    nav_links = Array.from(grabAll('.nav-item > .nav-link')),
    get_document_signed = grab('[name=get_document_signed]'),
    self_sign_document = grab('[name=get_document_self_signed]'),
    self_sign_switch = grab('[name=self_sign_switch]'),
    self_sign_component = grab('.self_sign_component'),
    regular_sign_component = grab('.regular_sign_component'),
    connect_account = grab('.main_login_sign_up'),
    disconnect_account = grab('.main_logout_sign_up'),
    buy_product = grab('.buy_product'),
    get_document_redacted = grab('[name=get_document_redacted]'),
    file_url = Office.context.document.url || "";

    
    docName = file_url.getName() || (default_name + default_ext);

    titles.forEach(function(title) { title.innerHTML = docName; });
    setLoginState().then(function(){
      loading_cover.classList.add('hide-loading');
    });
      

      nav_links.forEach(function(link) {
        link.addEventListener('click', function(evt) {
          evt.preventDefault();
          nav_links.forEach( function(l) {
            l.classList.remove('active');
            l.setAttribute('aria-selected',false);
            document.getElementById(l.hash.substr(1)).classList.remove('show','active');
          });
          this.classList.add('active');
          this.setAttribute('aria-selected',true);
          document.getElementById(this.hash.substr(1)).classList.add('show','active');
        });
      });

      connect_account.addEventListener('click',connectAccount);
      disconnect_account.addEventListener('click',disconnectAccount);

      self_sign_switch.addEventListener('change',function(){
        if(this.checked==true){
          self_sign_component.showItem();
          regular_sign_component.hideItem();
        }else {
          self_sign_component.hideItem();
          regular_sign_component.showItem();
        }
      });

      buy_product.addEventListener('click',function(){
        Office.context.ui.displayDialogAsync(base_url + '/pricing',function(dbr){
          if(dbr.status === Office.AsyncResultStatus.Succeeded) {
            refreshOnDialogClose = true;
            dialogBox = dbr.value;
            dialogBox.addEventHandler(Office.EventType.DialogMessageReceived,processMessage);
            dialogBox.addEventHandler(Office.EventType.DialogEventReceived, processDialogEvent);
          } else {
            $.notify("Something went wrong internally, please contact support at support@docendorse.com",'error');
          }
        });
      });


      self_sign_document.addEventListener('click',function(evt){
        if(!isRunning){
            getFileURL().then(function(file_url){
              self_sign_document.querySelector('.fa-spin').makeVisible();
              var msdoc = uniqid("msdoc_"),
              doc2Name = file_url.getName(),
              final_name = msdoc + '_' + doc2Name.removeExt().clean() + '.pdf';
              isRunning = true;
              uploadFileStream({
                  docutype: 'user_document_to_sign',
                  transactionid: msdoc,
                  name: final_name,
                  params: { esign_name: '', esign_message:''},
                  transactiontype: 'self_sign_document'
              }).then(getDocumentSelfSigned)
              .catch(handleUploadError)
              .finally(function(){
                self_sign_document.querySelector('.fa-spin').makeHidden();
                isRunning = false;
              });
            }).catch(function(){
              $.notify('Could not determine file name','error');
              isRunning = false;
            });
        } else {
          $.notify('Signature process is running','error');
        }
      });


      get_document_signed.addEventListener('click',function(evt){
        if(!isRunning){
          var parent = this.closest('.pane_component');

          if(!isFormEmpty(parent)){
            getFileURL().then(function(file_url){
              get_document_signed.querySelector('.fa-spin').makeVisible();
              var msdoc = uniqid("msdoc_"),
              doc2Name = file_url.getName(),
              final_name = msdoc + '_' + doc2Name.removeExt().clean() + '.pdf';
              isRunning = true;
              uploadFileStream({
                  docutype: 'user_document_to_sign',
                  transactionid: msdoc,
                  name: final_name,
                  params: Serialize(parent),
                  transactiontype: 'esign_document'
              }).then(getDocumentSigned)
              .catch(handleUploadError)
              .finally(function(){
                get_document_signed.querySelector('.fa-spin').makeHidden();
                isRunning = false;
              });
            }).catch(function(){
              $.notify('Could not determine file name','error');
              isRunning = false;
            });
          } else{
            parent.classList.add('was-validated');
            $.notify('Some data is missing','error');
          }
        } else {
          $.notify('Signature process is running','error');
        }
      });

      get_document_redacted.addEventListener('click',function(evt){
        if(!isRunning){
          getFileURL().then(function(file_url){
            var msdoc = uniqid("msdoc_"),
            doc2Name = file_url.getName(),
            final_name = msdoc + '_' + doc2Name.removeExt().clean() + '.pdf';
            isRunning = true;
            get_document_redacted.querySelector('.fa-spin').makeVisible();

            uploadFileStream({
                docutype: 'user_document_to_sign',
                transactionid: msdoc,
                name: final_name,
                params: {},
                transactiontype: 'redact_document'
            }).then(getDocumentRedacted)
            .catch(handleUploadError)
            .finally(function(){
              get_document_redacted.querySelector('.fa-spin').makeHidden();
              isRunning = false;
            });

          }).catch(function(){
            $.notify('Could not determine file name','error');
            isRunning = false;
          });
        } else {
          $.notify('Redact process is running','error');
        }
      });
  } else {
    secondary_container.showItem();
    main_container.hideItem();
    loading_cover.classList.add('hide-loading');
  }
}

// ==================================


Office.onReady( function (info){
  if (navigator.userAgent.indexOf("Trident") == -1 ){
    installAddIn(info);
  }
});


// ==================================


function processMessage(arg) {
  var message = JSON.parse(arg.message);
  switch(message.status){
    case 'success_app_registered':
    case 'success_app_updated':
      setToken(message);
      setLoginState().finally(function(){
        dialogBox.close();
        $.notify(message.description,'success');
      });
      break;
    case 'success_app_revoked':
    case 'not_connected_app_connection':
      localStorage.clear();
      setLoginState().finally(function(){
        dialogBox.close();
        $.notify(message.description,'success');
      });
      break;
    case 'close_dialog':
      dialogBox.close();
      break;
    case 'signature_request_sent':
      resetForm(grab('#pills-profile'));
      dialogBox.close();
      $.notify(message.description,'success');
      break;
    case 'redact_document_completed':
    case 'self_signed_document_completed':
      dialogBox.close();
      $.notify(message.description,'success');
      break;
    case 'purchase_addon_complete':
      setLoginState().finally(function(){
        dialogBox.close();
        $.notify(message.description,'success');
      });
      break;
  }
}



function getDocumentSigned(item){
  Office.context.ui.displayDialogAsync(base_url + '/routines_ms_app?instruct=sign_document_connected_app&app_code=microsoft_doc_man&app_transaction_id=' + item.id,function(dbr){
    if(dbr.status === Office.AsyncResultStatus.Succeeded) {
      dialogBox = dbr.value;
      dialogBox.addEventHandler(Office.EventType.DialogMessageReceived,processMessage);
      dialogBox.addEventHandler(Office.EventType.DialogEventReceived, processDialogEvent);
    } else {
      $.notify("Something went wrong internally, please contact support at support@docendorse.com",'error');
    }
  });
}


function getDocumentSelfSigned(item){
  Office.context.ui.displayDialogAsync(base_url + '/routines_ms_app?instruct=self_sign_document_connected_app&app_code=microsoft_doc_man&app_transaction_id=' + item.id,function(dbr){
    if(dbr.status === Office.AsyncResultStatus.Succeeded) {
      dialogBox = dbr.value;
      dialogBox.addEventHandler(Office.EventType.DialogMessageReceived,processMessage);
      dialogBox.addEventHandler(Office.EventType.DialogEventReceived, processDialogEvent);
    } else {
      $.notify("Something went wrong internally, please contact support at support@docendorse.com",'error');
    }
  });
}


function getDocumentRedacted(item){
  Office.context.ui.displayDialogAsync(base_url + '/routines_ms_app?instruct=redact_document_connected_app&app_code=microsoft_doc_man&app_transaction_id=' + item.id,function(dbr){
    if(dbr.status === Office.AsyncResultStatus.Succeeded) {
      dialogBox = dbr.value;
      dialogBox.addEventHandler(Office.EventType.DialogMessageReceived,processMessage);
      dialogBox.addEventHandler(Office.EventType.DialogEventReceived, processDialogEvent);
    } else {
      $.notify("Something went wrong internally, please contact support at support@docendorse.com",'error');
    }
  });
}


function connectAccount(){
  Office.context.ui.displayDialogAsync( base_url + '/routines_ms_app?instruct=connect_app&app_code=microsoft_doc_man',{
    displayInIframe: false
  },function(dbr){
    if(dbr.status === Office.AsyncResultStatus.Succeeded) {
      dialogBox = dbr.value;
      dialogBox.addEventHandler(Office.EventType.DialogMessageReceived,processMessage);
      dialogBox.addEventHandler(Office.EventType.DialogEventReceived, processDialogEvent);
    } else {
      $.notify("Something went wrong internally, please contact support at support@docendorse.com",'error');
    }
  });
}


function disconnectAccount() {
  Office.context.ui.displayDialogAsync(base_url + '/routines_ms_app?instruct=disconnect_app&app_code=microsoft_doc_man',function(dbr){
    if(dbr.status === Office.AsyncResultStatus.Succeeded) {
      dialogBox = dbr.value;
      dialogBox.addEventHandler(Office.EventType.DialogMessageReceived,processMessage);
      dialogBox.addEventHandler(Office.EventType.DialogEventReceived, processDialogEvent);
    } else {
      $.notify("Something went wrong internally, please contact support at support@docendorse.com",'error');
    }
  });
}


function purchaseSubscription(){
  Office.context.ui.displayDialogAsync(base_url + '/routines_ms_app?instruct=purchase_subscription&app_code=microsoft_doc_man',function(dbr){
    if(dbr.status === Office.AsyncResultStatus.Succeeded) {
      refreshOnDialogClose = true;
      dialogBox = dbr.value;
      dialogBox.addEventHandler(Office.EventType.DialogMessageReceived,processMessage);
      dialogBox.addEventHandler(Office.EventType.DialogEventReceived, processDialogEvent);
    } else {
      $.notify("Something went wrong internally, please contact support at support@docendorse.com",'error');
    }
  });
}



function setLoginState(){
  return new Promise(function(resolve,reject){
    loginState({'action': 'get_app_state'}).then(function(_state){ 
      var state = JSON.parse(_state),
      scene = null;
      if (state.status == 'user_authenticated_reg_no_plan') {
        scene = setScene('signed_in_no_product',state);
      } else if (state.status == 'user_authenticated_has_product'){
        scene = setScene('signed_in',state);
      } else if (state.status == 'account_unverified') {
        scene = setScene('signed_out');
        $.notify('Your email/account has not been verified. Please check your email for the verification link.','error');
      } else {
        scene = setScene('signed_out');
      }
      scene.then(resolve).catch(reject);
    }).catch(reject);
  });
}


function processDialogEvent(arg){
  switch (arg.error) {
      case 12002:
        $.notify("Cannot load page please contact support at support@docendorse.com.",'error');
        break;
      case 12003:
        $.notify("An unsecured page is being opened please contact support at support@docendorse.com","error");
        break;
      case 12006: 
        if(refreshOnDialogClose){
          setLoginState();
          refreshOnDialogClose = false;
        }
        break;
      case 12009:
        $.notify("Blocking the pop up will prevent the operation from being carried out. Please enable the popup to continue.","error"); 
        break;
      case 12011:
        $.notify("Your browser is configured to block popups, please enable popup or use different browser to continue.","error"); 
        break;
      default: break;
  }
}



function setScene(scene,data = {}){
  var visible_on_logged_out = Array.from(grabAll('.visible_on_logged_out')),
  visible_on_logged_in = Array.from(grabAll('.visible_on_logged_in')),
  paid_esign_plan = Array.from(grabAll('.paid_esign_plan')),
  esign_tip = grab('.esign_tip'),
  redact_tip = grab('.redact_tip'),
  paid_redact_addon = Array.from(grabAll('.paid_redact_plan')),
  profile = grab('.box-profile'),
  buy = grab('.buy_product'),
  _data = {};

  esign_tip.innerHTML = "You need to connect your app to your DocEndorse account to esign documents";
  
  switch(scene){
    case 'signed_in':
      visible_on_logged_in.forEach(function(el) { el.showItem(); } );
      visible_on_logged_out.forEach(function(el) { el.hideItem(); } );
      paid_esign_plan.forEach(function(el) { el.showItem(); } );
      _data = data;
      buy.hideItem();
      esign_tip.hideItem();

      if(!data.IsARedactUser){
        paid_redact_addon.forEach(function(el){el.hideItem()});
        redact_tip.showItem();
      } else {
        paid_redact_addon.forEach(function(el){el.showItem()});
        redact_tip.hideItem();
      }
      break;
    case 'signed_out':
      visible_on_logged_in.forEach(function(el){ el.hideItem(); } );
      visible_on_logged_out.forEach(function(el){ el.showItem(); } );
      paid_esign_plan.forEach(function(el) { el.hideItem(); } );
      paid_redact_addon.forEach(function(el){el.hideItem();});
      redact_tip.showItem();
      esign_tip.showItem();
      break;
    case 'signed_in_no_product':
      visible_on_logged_in.forEach(function(el) { el.showItem(); } );
      visible_on_logged_out.forEach(function(el) { el.hideItem(); } );
      paid_esign_plan.forEach(function(el) { el.hideItem(); } );
      paid_redact_addon.forEach(function(el){el.hideItem()});
      redact_tip.showItem();
      esign_tip.showItem();

      esign_tip.innerHTML = "You need to purchase a DocEndorse subscription.";
      _data = data;

      buy.showItem();
      break;
  }
  return setHTMLData(profile,_data);
}



function loginState( data ){
  return webFetchPromise({
    'url': base_url + '/api/public/connected_apps_manager.php',
    'type': 'post',
    'content': "application/json",
    'payload': data,
    'responseType': 'text',
    'authorization': get_token()
  });
}



function setToken(data){
  localStorage.setItem("access_token", data.access_token);
}


function get_token(){
  return localStorage.getItem("access_token");
}


function isPowerPointOnline(){
  if (Office.context.host === Office.HostType.PowerPoint &&
    Office.context.platform === Office.PlatformType.OfficeOnline) {
    return true;
  } else {
    return false;
  }
}


function uploadFileStream(credentials){
  return new Promise(function(resolve,reject){

    var fileType = isPowerPointOnline() == true ? Office.FileType.Compressed : Office.FileType.Pdf;

    Office.context.document.getFileAsync(
      Office.FileType.Pdf,{ sliceSize: 65536 },
        function (result) {
          if (result.status === Office.AsyncResultStatus.Succeeded) {
              var myFile = result.value, counter = 0;
              credentials.total_chunks = myFile.sliceCount;
               var uplodNextChunk = function(){

                  myFile.getSliceAsync(counter,function (slice) {
                    if(slice.status == Office.AsyncResultStatus.Succeeded){
                      counter++;
                      credentials.chunk_number = counter;
                      bufferToBase64(slice.value.data).then(function(src){
                          credentials.data = src.split(',')[1];
                          credentials.type = (src.split(';')[0]).split(':')[1];
                          webFetchPromise({
                            'url': base_url+ '/api/public/connected_apps_upload_multipart.php',
                            'type': 'post',
                            'payload': credentials,
                            'content': "application/json",
                            'responseType': 'text',
                            'authorization': get_token()
                          }).then(function(_data){
                            var data = JSON.parse(_data);
                            if(data.status == 'success'){
                              if(counter < myFile.sliceCount) {
                                uplodNextChunk();
                              } else {
                                myFile.closeAsync(function (post) {
                                  if(post.status === Office.AsyncResultStatus.Succeeded) {
                                      resolve( data );
                                  } else {
                                    reject({
                                      status: 'error',
                                      description: post.error.message + ' level 4'
                                    });
                                  }
                                });
                              }
                            } else {
                              myFile.closeAsync(function (post) {
                                if(post.status === Office.AsyncResultStatus.Succeeded) {
                                  reject(data);
                                } else {
                                  reject({
                                    status: 'error',
                                    description: post.error.message + ' level 3'
                                  });
                                }
                              });
                            }
                          });
                      }).catch(function(){
                        myFile.closeAsync(function (post) {
                          reject({
                            status: 'error',
                            description: 'Could not base64 encode data.'
                          });
                        });
                      });
                    } else {
                      myFile.closeAsync(function (post) {
                        reject({
                          status: slice.status,
                          description: slice.error.message + ' level 2'
                        });

                      });
                    }
                  });
               };
               uplodNextChunk();
          } else {
            reject({
              status: result.status,
              description: result.error.message + ' level 1'
            });
          }
      });
  });
}


function bufferToBase64(buffer) {
  return new Promise(function(r){
    var reader = new FileReader();
    reader.onload = function(){
      r(reader.result);
    }
    reader.readAsDataURL( new Blob(
      [new Uint8Array(buffer)],
      { type: 'application/pdf' }
      ));
  });
}


function getFileURL(){
  return new Promise(function(r){
    var url = Office.context.document.url || "";
    if (url.length > 0){
      Office.context.document.getFilePropertiesAsync(function (fProp){
        if(fProp.status == Office.AsyncResultStatus.Succeeded){
          r( fProp.value.url );
        } else {
          r("");
        }
      });
    } else {
      r(url);
    }
  });
}



function handleUploadError(err){
  if(err.status == 'no_perms'){
    $.notify('Your app is disconnected. Please reconnect','error');
    setLoginState();
  } else if (err.status == 'purchase_plan_app_connection'){
    purchaseSubscription();
  } else if (err.status == 'purchase_redact_addon'){
    $.notify("You need to be assigned to redact addon, please consult your IT department",'error');
  } else {
    if (!isPowerPointOnline()){
      $.notify("Could not upload file please try again or contact support at support@docendorse.com",'error');
    } else {
      // https://github.com/OfficeDev/office-js/issues/5006
      $.notify("Currently not able to convert your PowerPoint online files to PDF for processing. Please use the desktop version until further notice.",'error');
    }
  }
}